<template>
  <span
    class="label font-weight-bold label-lg label-inline"
    v-bind:class="{
      'label-light-warning': merchantId === 1,
      'label-light-success': merchantId === 2,
    }"
    style="width: max-content"
    >{{ getMerchantName(merchantId) }}</span
  >
</template>

<script>
export default {
  props: ['merchantId'],
  data() {
    return {};
  },
  methods: {
    getMerchantName(merchantId) {
      switch (merchantId) {
        case 1:
          return 'VDD';
        case 2:
          return 'TGMC';
      }
    },
  },
};
</script>
