<template>
  <div class="warranty-bill-page">
    <KTCodePreview v-bind:title="'Hóa đơn bảo hành'">
      <!-- header content -->
      <template v-slot:toolbar>
        <b-row>
          <div>
            <b-dropdown
              size="sm"
              right
            >
              <template slot="button-content">
                <i class="fas fa-cog text-1rem"></i>
                <span class="font-weight-bolder">Thao tác</span>
              </template>
              <b-dropdown-item @click="showModalExcel">
                <span>
                  <i class="far fa-file-excel text-1rem"></i>
                  &nbsp; Xuất Excel</span>
              </b-dropdown-item>
              <b-dropdown-item @click="exportStocks">
                <span>
                  <i class="menu-icon flaticon2-delivery-package text-1rem"></i>
                  &nbsp; Tạo phiếu chuyển kho</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>

          <div
            v-if="checkPermission('BILL_INSERT')"
            class="ml-3"
          >
            <button
              @click="createWarrantyBill()"
              type="button"
              class="btn btn-primary font-weight-bolder btn-sm"
            >
              <i class="flaticon2-add-1 text-1rem"></i>Thêm mới
            </button>
          </div>
        </b-row>
      </template>
      <template v-slot:preview>
        <!-- fist filter row -->
        <b-row class="mb-5">
          <b-col>
            <b-form-select
              size="sm"
              class="select-style"
              v-model="selectedCompany"
              :options="listCompany"
              value-field="id"
              text-field="name"
              v-on:change="onChangeCompany"
            >
              <template v-slot:first>
                <b-form-select-option
                  :value="null"
                  disabled
                >-- Doanh nghiệp --</b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
          <b-col>
            <BDropdownCustom
              v-model="valueStore"
              :options="filteredStore"
              :placeholder="'cửa hàng'"
              :matchKey="['name']"
              :searchable="true"
            />
          </b-col>
          <b-col><b-input
              placeholder="Nhập tên sản phẩm"
              v-model="searchProductName"
              size="sm"
            ></b-input></b-col>
          <b-col>
            <div class="search-col">
              <b-input-group>
                <date-picker
                  placeholder="Từ ngày"
                  class="form-control form-control-sm"
                  :config="dpConfigs.date"
                  v-model="dateFrom"
                ></date-picker>
              </b-input-group>
              <span class="ml-1 mr-1"></span>
              <b-input-group>
                <date-picker
                  placeholder="Tới ngày"
                  class="form-control form-control-sm"
                  :config="dpConfigs.date"
                  v-model="dateTo"
                ></date-picker>
              </b-input-group>
            </div>
          </b-col>
          <b-col>
            <Autosuggest
              :model="searchEmployee"
              :suggestions="filteredOptionsEmployee"
              :placeholder="'Nhân viên bảo hành'"
              :limit="10"
              @select="onSelectedEmployee"
              @change="onInputChangeEmployee"
            >
              <template #custom="{ suggestion }">
                <div>
                  <span>{{ suggestion.item.fullName }}</span>
                </div>
              </template>
            </Autosuggest>
          </b-col>
        </b-row>
        <!-- end of first filter row -->

        <!-- second filter row -->
        <b-row>
          <b-col>
            <b-input
              placeholder="Nhập ID hóa đơn"
              v-model="searchId"
              size="sm"
            ></b-input>
          </b-col>
          <b-col><b-input
              placeholder="Nhập tên KH/Sdt"
              v-model="searchNameOrPhone"
              size="sm"
            ></b-input></b-col>
          <b-col>
            <b-input
              placeholder="Nhập mã IMEI"
              v-model="searchImei"
              size="sm"
            ></b-input>
          </b-col>
          <b-col>
            <Autosuggest
              :model="selectedWarrantyReason"
              :suggestions="filteredWarrantyOptions"
              placeholder="lý do bảo hành"
              :limit="10"
              @change="onInputWarrantyReason"
              @select="onSelected($event)"
            ></Autosuggest>
          </b-col>
          <b-col>
            <b-form-group>
              <b-form-select
                :options="listWarrantyStatus"
                class="select-style"
                size="sm"
                v-model="searchWarrantyStatus"
                value-field="id"
                text-field="name"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col md="1">
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter"
            >Lọc</b-button>
          </b-col>
        </b-row>
        <!-- end of second filter row -->
        <!-- table section -->
        <table class="table table-bordered-list table-hover col-md-12">
          <thead>
            <tr>
              <th class="text-center header-table-color">
                <b-form-checkbox
                  v-model="checkAllBills"
                  size="lg"
                  @change="changeBillSelected('all')"
                ></b-form-checkbox>
              </th>
              <th>ID</th>
              <th>Khách hàng</th>
              <th>Sản phẩm</th>
              <th>Giá</th>
              <th>SL</th>
              <th>Tổng tiền</th>
              <th>Thanh toán</th>
              <th>Ghi chú</th>
              <th>Trạng thái</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(bill, i) in bills">
              <!-- row head -->
              <tr
                :key="i"
                @mouseover="hoverRow"
                @mouseleave="outRow"
                :data="`row_${bill.billNumber}`"
              >
                <td
                  colspan="11"
                  style="
                    padding: 5px !important;
                    border-bottom: none !important;
                  "
                >
                  <div class="d-flex">
                    <div>
                      <b> {{ bill.storeName }} </b>
                      <span
                        class="ml-1"
                        v-if="bill.companyName"
                        style="font-size: 10px"
                      >
                        ( {{ bill.companyName }} )
                      </span>
                    </div>
                  </div>

                  <div class="staff-info">
                    <span class="mr-4">Ngày tạo:
                      <span class="text-primary">
                        {{ bill.createdAt }}</span></span>
                    <span class="mr-4">
                      <span class="mr-1">TVBH:</span>
                      <span v-if="bill.saleName">
                        {{ bill.saleName }}
                      </span>
                      <span
                        v-else
                        class="text-danger"
                      > Chưa được gán </span>
                    </span>
                  </div>
                </td>
              </tr>
              <!-- end of row head -->
              <tr
                :key="i + 10"
                v-if="bill.showDetail"
              >
                <td
                  colspan="8"
                  style="border-bottom: none !important"
                >
                  <b-col cols="3"> </b-col>
                </td>
              </tr>
              <!-- row info -->
              <template v-for="(billItem, j) in bill.listProduct">
                <tr
                  :key="i + j + billItem.id"
                  @mouseover="hoverBill"
                  @mouseleave="outBill"
                  :data="`row_${bill.billNumber}`"
                >
                  <td
                    :rowspan="bill.listProduct.length"
                    class="tdCenter"
                    v-if="j === 0 || bill.show ? true : false"
                  >
                    <b-form-checkbox
                      size="lg"
                      class="text-center"
                      v-model="bill.selected"
                      @change="changeBillSelected('one')"
                    />
                  </td>
                  <!-- column bill number -->
                  <td
                    :rowspan="bill.listProduct.length"
                    v-if="j === 0 || bill.show ? true : false"
                    class="tdTextAlign tdCode"
                  >
                    <span
                      @click="updateWarrantyBill(bill)"
                      style="cursor: pointer"
                      class="text-primary"
                    >{{ bill.billNumber }}</span>

                    <div v-if="bill.returnFromBillNumber">
                      <p class="return-from-bill">Bảo hành từ HD:</p>
                      <span
                        v-on:click="onClickBillNumber(bill)"
                        v-text="bill.returnFromBillNumber"
                        class="text-primary"
                        style="cursor: pointer; color: #3699ff"
                      ></span>
                    </div>
                  </td>
                  <!-- end of column bill number -->

                  <!-- column customer -->
                  <td
                    :rowspan="bill.listProduct.length"
                    v-if="j === 0 || bill.show ? true : false"
                    class="tdTextAlign tdCustomer"
                  >
                    <p
                      class="orderCode"
                      style="
                        margin-bottom: 0;
                        cursor: pointer;
                        font-weight: 550;
                      "
                    >
                      {{ bill.customerName }}
                    </p>
                    <p
                      class="orderCode"
                      style="margin-bottom: 0; cursor: pointer"
                    >
                      {{ bill.customerMobile }}
                    </p>
                  </td>
                  <!-- end of column customer -->

                  <!-- column product name -->
                  <td class="tdTextAlign tdProductName">
                    <div v-if="billItem.typeProduct !== 3">
                      <div
                        v-if="billItem.productType === 2"
                        class="font-size-gift"
                      >
                        <i
                          style="color: #2e7d32"
                          class="fas fa-gift ml-4 mr-1"
                          title="Quà tặng"
                        ></i>
                        {{ billItem.productName }}
                        <MerchantLabel :merchantId="billItem.merchantId" />
                      </div>
                      <div
                        v-else-if="billItem.productType === 3"
                        class="font-size-attach"
                      >
                        <i
                          style="color: #37474f"
                          class="fas fa-tags ml-4 mr-1"
                          title="Bán kèm"
                        ></i>
                        {{ billItem.productName }}
                        <MerchantLabel :merchantId="billItem.merchantId" />
                      </div>
                      <div v-else>
                        {{ billItem.productName }}
                        <MerchantLabel :merchantId="billItem.merchantId" />
                        <i
                          v-if="billItem.isLostProduct"
                          v-b-tooltip.hover
                          title="Sản phẩm mất xác"
                          class="fa fa-exclamation-triangle lost-product-icon"
                        ></i>
                      </div>
                      <p
                        class="text-imei-no mb-0"
                        v-if="billItem.imeiCode.trim() !== ''"
                      >
                        IMEI: {{ billItem.imeiCode }}
                      </p>

                      <div
                        class="mt-2 d-table"
                        v-if="billItem.newProductId"
                      >
                        <div class="d-table-row">
                          <div class="d-table-cell pl-3 pr-2">
                            <i class="fas fa-level-up-alt fa-rotate-90 pl-1"></i>
                          </div>
                          <div class="d-table-cell">
                            <span
                              class="d-flex"
                              v-if="billItem.newProductCode"
                            >({{ billItem.newProductCode }})</span>
                            <span class="font-weight-bolder">
                              {{ billItem.newProductName }}
                              <span
                                class="label font-weight-bold label-lg label-inline"
                                v-bind:class="{
                                  'label-light-warning':
                                    billItem.merchantId === 1,
                                  'label-light-success':
                                    billItem.merchantId === 2,
                                }"
                                style="padding: 0"
                              >
                                <MerchantLabel :merchantId="billItem.merchantId" />
                              </span>
                              <p
                                v-if="billItem.newImeiCode"
                                class="text-imei-no mb-0"
                              >
                                IMEI: {{ billItem.newImeiCode }}
                              </p>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="text-muted fs-7 fw-bold">
                        <span class="warranty-reason">Lý do: {{ billItem.warrantyReason }}</span>
                      </div>
                    </div>
                    <div v-else>
                      <div
                        class="table-responsive"
                        v-show="
                          billItem.comboItems !== undefined &&
                            billItem.comboItems.length > 0
                        "
                      >
                        <table>
                          <tr>
                            <td style="border: none; padding-left: 0">
                              <p class="m-0">
                                {{ billItem.productName }}
                                <MerchantLabel :merchantId="billItem.merchantId" />
                              </p>
                            </td>
                          </tr>
                          <tr
                            v-for="(items, i) in billItem.comboItems"
                            :key="i"
                            style="border-top: 1px solid gray"
                          >
                            <td style="
                                border: none;
                                width: -webkit-fill-available;
                              ">
                              <i
                                style="color: rgb(24, 28, 50)"
                                class="fas fa-cube"
                              ></i>
                              {{ items.productName }}
                              <MerchantLabel :merchantId="billItem.merchantId" />
                              <i style="font-size: unset; color: unset">({{ convertPrice(items.sellingPrice) }})</i>
                            </td>
                            <td style="border: none; font-size: 13px">
                              {{ items.quantity }}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </td>
                  <!-- end of column product name -->

                  <td class="tdTextAlignPrice tdProductPrice">
                    {{ convertPrice(billItem.productPrice) }}
                    <span
                      class="d-flex justify-content-end"
                      v-if="billItem.newProductPrice > 0"
                    >
                      <i class="fas fa-level-up-alt fa-rotate-90 mr-2"></i>{{ convertPrice(billItem.newProductPrice) }}</span>
                  </td>
                  <td class="tdTextAlignPrice tdQuantity">
                    {{ billItem.quantity }}
                    <span
                      class="d-flex justify-content-end"
                      v-if="billItem.newQuantity > 0"
                    >
                      <i class="fas fa-level-up-alt fa-rotate-90 mr-2"></i>{{ convertPrice(billItem.newQuantity) }}</span>
                  </td>
                  <!-- column total price -->
                  <td class="tdTextAlignPrice tdTotalAmount">
                    {{ convertPrice(billItem.totalPrice) }}
                    <span
                      class="d-flex justify-content-end"
                      v-if="billItem.newTotalPrice > 0"
                    >
                      <i class="fas fa-level-up-alt fa-rotate-90 mr-2"></i>{{ convertPrice(billItem.newTotalPrice) }}</span>
                  </td>
                  <!-- end of column total price -->

                  <!-- column purchase -->
                  <td
                    class="tdStatus align-middle text-center"
                    v-if="j === 0 || bill.show ? true : false"
                    :rowspan="bill.listProduct.length"
                  >
                    <p
                      class="mb-0"
                      v-if="bill.cashAmount > 0"
                    >
                      <i
                        v-b-tooltip.hover
                        title="Tiền mặt"
                        class="fas fa-money-bill header-table-color"
                        style="font-size: inherit"
                      ></i>
                      {{
                          bill.cashAmount > 0
                            ? convertPrice(bill.cashAmount)
                            : 0
                        }}
                    </p>
                    <p
                      class="mb-0"
                      v-if="bill.transferAmount > 0"
                    >
                      <i
                        v-b-tooltip.hover
                        title="Tiền chuyển khoản"
                        class="fas fa-university header-table-color"
                        style="font-size: inherit"
                      ></i>
                      {{
                          bill.transferAmount
                            ? convertPrice(bill.transferAmount)
                            : 0
                        }}
                    </p>
                  </td>
                  <!-- end of column purchase -->

                  <!-- column warranty note -->
                  <td
                    class="tdNote wrapword"
                    v-if="j === 0 || bill.show ? true : false"
                    :rowspan="bill.listProduct.length"
                  >
                    <p>{{ truncateText(bill.warrantyNote) }}</p>
                    <p
                      v-if="bill.warrantyNote && bill.saleNote"
                      class="dashed"
                    ></p>
                    <p>
                      {{ truncateText(bill.saleNote) }}
                    </p>
                  </td>
                  <!-- end of column warranty note -->

                  <!-- column warranty status -->
                  <td
                    class="tdStatus align-middle text-center"
                    v-if="j === 0 || bill.show ? true : false"
                    :rowspan="bill.listProduct.length"
                  >
                    <span
                      style="width: max-content"
                      v-text="ENUM_NAME.BILL_STATUS_NAME[bill.status] || ''"
                      v-bind:class="bindClassNameStatus(bill.status)"
                    >
                    </span>
                  </td>

                  <!-- end of column warranty status -->

                  <!-- column action -->
                  <td
                    :rowspan="bill.listProduct.length"
                    v-if="j === 0 || bill.show ? true : false"
                    class="tdTextAlign tdAction"
                  >
                    <div class="d-flex justify-content-center">
                      <b-dropdown
                        dropup
                        size="sm"
                        id="dropdown-left"
                        no-caret
                        right
                      >
                        <template slot="button-content">
                          <i class="flaticon2-settings text-1rem pr-0"></i>
                        </template>
                        <b-dropdown-item @click="updateWarrantyBill(bill)">
                          <span style="color: #3f4254; font-size: 12px">
                            <i class="flaticon2-pen text-1rem"></i>
                            &nbsp; Chỉnh sửa
                          </span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="transferWarrantyStock(bill)">
                          <span style="color: #3f4254; font-size: 12px">
                            <i class="flaticon2-paperplane text-1rem"></i>
                            &nbsp; Tạo phiếu chuyển kho
                          </span>
                        </b-dropdown-item>
                        <!-- create warranty bill button -->
                        <b-dropdown-item
                          v-if="bill.status === WARRANTY_BILL_STATUS.DONE"
                          @click="reCreateBill(bill)"
                        >
                          <span style="color: #3f4254; font-size: 12px">
                            <i
                              style="font-size: 1rem"
                              class="fas fa-toolbox"
                            ></i>
                            &nbsp; Tạo hóa đơn bảo hành
                          </span>
                        </b-dropdown-item>
                        <b-dropdown-item
                          @click="showDeleteAlert(bill)"
                          v-if="
                            checkPermission('BILL_DELETE') &&
                              bill.companyId === 3
                          "
                        >
                          <span style="color: #3f4254; font-size: 12px">
                            <i
                              style="color: #d33"
                              class="flaticon2-rubbish-bin-delete-button text-1rem"
                            ></i>
                            &nbsp; Xóa
                          </span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </td>
                  <!-- end of column action -->
                </tr>
              </template>
              <!-- end of row info -->
            </template>
          </tbody>
        </table>
        <!-- end of table section -->

        <!-- pagination section -->
        <b-row>
          <b-col>
            <p
              class="mt-3 text-dark"
              style="font-weight: 500"
            >
              Tổng số hóa đơn:
              {{ convertPrice(totalItems) }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              v-model="page"
              v-show="totalPages >= 2"
              class="customPagination"
              :link-gen="linkGen"
              :number-of-pages="totalPages"
              use-router
              @change="fetchWarrantyBill"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
              prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
        <!-- end of pagination section -->
      </template>
    </KTCodePreview>
    <WarrantyBillExcel
      :param="excelParams"
      :mode="5"
    />
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import MerchantLabel from '../../components/MerchantLabel.vue';
import datePicker from 'vue-bootstrap-datetimepicker';
import moment from 'moment';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import {
  BILL_TYPE,
  WARRANTY_BILL_STATUS,
  WARRANTY_ERROR_BY,
} from '@/utils/enum';
import ApiService from '@/core/services/api.service';
import jQuery from 'jquery';
import { cloneDeep } from 'lodash';
import * as ENUM from '@/utils/enum';
import * as ENUM_NAME from '@/utils/enum-name';
import Swal from 'sweetalert2';
import localData from '@/utils/saveDataToLocal';
import {
  makeToastSuccess,
  makeToastFaile,
  removeAccents,
} from '@/utils/common';
import BDropdownCustom from '@/view/base/bootstrap/BDropdownCustom.vue';
import WarrantyBillExcel from '../../components/bills/WarrantyBillExcel';

export default {
  components: {
    KTCodePreview,
    MerchantLabel,
    datePicker,
    Autosuggest,
    BDropdownCustom,
    WarrantyBillExcel,
  },
  data() {
    return {
      excelParams: {
        fromDate: null,
        toDate: null,
      },
      checkAllBills: false,
      WARRANTY_ERROR_BY,
      WARRANTY_BILL_STATUS,
      selectedWarrantyReason: null,
      selectedWarrantyReasonId: null,
      warrantyReasonName: '',
      filteredWarrantyOptions: [],
      warrantyReasons: [],
      ENUM_NAME,
      hoverTable: {
        dataId: null,
      },
      showDetail: false,
      searchNote: '',
      searchId: '',
      searchImei: '',
      searchNameOrPhone: '',
      searchOrderId: '',
      searchEmployee: '',
      searchWarrantyStatus: '',
      listWarrantyStatus: [
        { id: '', name: 'Tất cả trạng thái' },
        { id: ENUM.WARRANTY_BILL_STATUS.NEW, name: 'Mới' },
        { id: ENUM.WARRANTY_BILL_STATUS.PROCESSING, name: 'Đang xử lý' },
        { id: ENUM.WARRANTY_BILL_STATUS.DONE, name: 'Đã trả bảo hành' },
      ],
      selectedEmployee: null,
      filteredOptionsEmployee: [],
      optionsEmployee: [],
      optionsStore: [],
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
        start: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
        end: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      dateFrom: moment().subtract(15, 'days').format('DD/MM/YYYY'),
      dateTo: moment().format('DD/MM/YYYY'),
      searchProductName: '',
      valueStore: [],
      filteredStore: [],
      page: 1,
      totalPages: 1,
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      totalItems: 0,
      selectedCompany: null,
      listCompany: [{ id: null, name: 'Tất cả' }],
      bills: [],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Hóa đơn', route: 'bills' },
      { title: 'Danh sách hóa đơn', route: 'bills' },
      { title: 'Danh sách hóa đơn bảo hành', route: '#' },
    ]);
    window.addEventListener('keyup', this.onFilterKeyEnter);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.onFilterKeyEnter);
  },
  async created() {
    this.fetchCompany();
    await this.fetchStoreByUser();
    this.getListEmployee();
    this.fetchWarrantyReasons();
    this.fetchWarrantyBill();
    this.page = this.$route.query.page;
    if (!this.page) {
      this.page = 1;
    }
  },
  methods: {
    reCreateBill(bill) {
      // get list product based on newProductId
      const products = bill.listProduct.filter(
        (product) => product.newProductId,
      );
      // catch error
      if (!products.length) {
        return makeToastFaile('Không có sản phẩm đổi mới');
      }

      this.$router.push({
        name: 'add-warranty-bill',
        query: { billId: bill.billNumber, action: 're-create' },
      });
    },
    createWarrantyBill() {
      this.$router.push({
        name: 'add-warranty-bill',
        query: { action: 'create', warrantyErrorBy: WARRANTY_ERROR_BY.STORE },
      });
    },
    onClickBillNumber(item) {
      let nameRouter = '';
      if (item.returnFromBillType === 1) {
        nameRouter = 'update-bill';
      }
      const { href } = this.$router.resolve({
        name: nameRouter,
        query: { id: item.returnFromBillNumber },
      });
      window.open(href, '_blank');
    },
    exportStocks() {
      const selectedBills = this.bills.filter((bill) => bill.selected);
      if (!selectedBills.length) {
        return makeToastFaile('Vui lòng chọn hóa đơn');
      }

      const storeIds = selectedBills.map((item) => item.storeId);
      const isFromOneStore = storeIds.every(
        (storeId) => storeId === storeIds[0],
      );

      if (!isFromOneStore) {
        return makeToastFaile('Vui lòng chọn hóa đơn từ 1 kho');
      }

      this.$router.push({
        name: 'transfer-warranty-stock',
        params: { bills: selectedBills },
      });
    },
    changeBillSelected: async function (prop) {
      if (prop === 'all') {
        this.bills.map((bill) => {
          bill.selected = this.checkAllBills;
          return bill;
        });
      }
    },
    truncateText(text) {
      const MAX_STRING = 200;
      if (!text || !text.length) {
        return '';
      }
      return text.length < MAX_STRING
        ? text
        : text.substring(0, MAX_STRING) + '...';
    },
    checkFeatureByUser: async function () {
      let result = true;
      await ApiService.setHeader();
      await ApiService.query('bills/check-warranty-feature')
        .then((response) => {
          const { status, message } = response.data;
          if (status !== 1) {
            makeToastFaile(message);
            result = false;
          }
        })
        .catch(() => {
          result = false;
        });

      return result;
    },
    onFilterKeyEnter(event) {
      if (event.key === 'Enter') {
        this.onFilter();
      }
    },
    fetchWarrantyReasons: function () {
      const param = {
        page: 1,
        pageSize: 10000,
      };
      const paramQuery = {
        params: param,
      };
      ApiService.query('warranty-reason-config', paramQuery).then(
        (response) => {
          this.warrantyReasons = [];
          const { result } = response.data.data;
          result.forEach((reason) => {
            const item = {
              id: reason.id,
              name: reason.name,
            };
            this.warrantyReasons.push(item);
          });

          this.filteredWarrantyOptions = [...this.warrantyReasons];
        },
      );
    },
    onSelected(option) {
      this.selectedWarrantyReasonId = option.item.id;
      this.warrantyReasonName = option.item.name;
    },
    onInputWarrantyReason(text) {
      if (!text) {
        this.warrantyReasonName = '';
        this.selectedWarrantyReasonId = null;
      }
      this.selectedWarrantyReason = text;
      const filteredData = this.warrantyReasons.filter((item) => {
        return (
          removeAccents(item.name)
            .toLowerCase()
            .indexOf(removeAccents(text.toLowerCase())) > -1
        );
      });

      this.filteredWarrantyOptions = [...filteredData];
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    deleteItem: async function (item) {
      ApiService.delete('/bills/warranty' + '/' + item.billNumber).then(
        (data) => {
          console.log({ data });
          const { status, message } = data.data;
          if (status === 1) {
            this.fetchWarrantyBill();
            makeToastSuccess(message);
          } else {
            makeToastFaile(message);
          }
        },
      );
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa hóa đơn!',
        text: 'Bạn có chắc muốn xóa hóa đơn này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    transferWarrantyStock(item) {
      this.$router.push({
        name: 'transfer-warranty-stock',
        query: { billId: item.billNumber },
      });
    },
    bindClassNameStatus(status) {
      let className = '';
      switch (status) {
        case ENUM.WARRANTY_BILL_STATUS.NEW: {
          className = 'badge badge-warning text-white';
          break;
        }
        case ENUM.WARRANTY_BILL_STATUS.PROCESSING: {
          className = 'badge badge-primary text-white';
          break;
        }
        case ENUM.WARRANTY_BILL_STATUS.DONE: {
          className = 'badge badge-success';
          break;
        }
        case ENUM.WARRANTY_BILL_STATUS.TRANSFER_STOCK: {
          className = 'badge badge-warning';
          break;
        }
      }
      return className;
    },
    onFilter() {
      this.$route.query.page = 1;
      this.fetchWarrantyBill();
    },
    getListEmployee() {
      this.optionsEmployee = [];
      this.filteredOptionsEmployee = [...this.optionsEmployee];
      let listStoreId = [];
      if (this.optionsStore) {
        this.optionsStore.forEach((element) => {
          if (element.checked) {
            listStoreId.push(element.code);
          }
        });
      }
      ApiService.get(
        `employees/get-by-store?employeeName=${this.searchEmployee}&storeId=${listStoreId}`,
      ).then((data) => {
        const employees = data.data.data;
        employees.map((element) => {
          this.optionsEmployee.push(element);
        });
        this.filteredOptionsEmployee = [...this.optionsEmployee];
      });
    },
    fetchStoreByUser: async function () {
      await ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          this.optionsStore = [];
          const stores = data.data.stores;
          stores.forEach((element) => {
            const option = {
              code: element.id,
              name: element.shortName,
              checked: false,
            };
            this.optionsStore.push(option);
            if (!data.data.viewAll) {
              const index = this.optionsStore.findIndex(
                (item) => item.code == element.id,
              );
              this.optionsStore[index].checked = true;
              this.valueStore.push(this.optionsStore[index]);
            }
          });
          this.filteredStore = cloneDeep(this.optionsStore);
        }
        if (this.$route.query.listStore) {
          this.valueStore = [];
          if (Array.isArray(this.$route.query.listStore)) {
            this.$route.query.listStore.forEach((id) => {
              const index = this.optionsStore.findIndex(
                (item) => item.code == id,
              );
              this.optionsStore[index].checked = true;
              this.valueStore.push(this.optionsStore[index]);
              this.filteredStore = cloneDeep(this.optionsStore);
            });
          } else {
            const index = this.optionsStore.findIndex(
              (item) => item.code == this.$route.query.listStore,
            );
            this.optionsStore[index].checked = true;
            this.valueStore.push(this.optionsStore[index]);
            this.filteredStore = cloneDeep(this.optionsStore);
          }
        }
      });
    },
    fetchCompany: async function () {
      this.listCompany = [];
      ApiService.get('/company').then((response) => {
        this.listCompany = response.data.data;
        this.listCompany.unshift({
          id: null,
          name: 'Tất cả',
        });
      });
    },
    hoverBill: function (evt) {
      const items = jQuery(evt.currentTarget);
      const dataId = jQuery(items[0]).attr('data');
      const rows = jQuery(`[data=${dataId}]`);
      rows.addClass('tr-rowspan-hover');
    },
    outBill: function (evt) {
      const items = jQuery(evt.currentTarget);
      const dataId = jQuery(items[0]).attr('data');
      const rows = jQuery(`[data=${dataId}]`);
      rows.removeClass('tr-rowspan-hover');
    },
    hoverRow: function (evt) {
      const items = jQuery(evt.currentTarget);
      const dataId = jQuery(items[0]).attr('data');
      if (this.hoverTable.dataId !== dataId) {
        const rows = jQuery(`[data=${dataId}]`);
        rows.addClass('tr-rowspan-hover');
        this.hoverTable.dataId = dataId;
      }
    },
    outRow: function (evt) {
      const items = jQuery(evt.currentTarget);
      const dataId = jQuery(items[0]).attr('data');
      const rows = jQuery(`[data=${dataId}]`);
      rows.removeClass('tr-rowspan-hover');
      this.hoverTable.dataId = null;
    },
    fetchWarrantyBill: async function () {
      let listStoreId = [];
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      if (this.valueStore.length) {
        listStoreId = this.valueStore.map(({ code }) => code);
      }
      const params = {
        page: this.page,
        limit: 10,
        id: this.searchId,
        customer: this.searchNameOrPhone,
        store: listStoreId,
        searchProduct: this.searchProductName,
        type: BILL_TYPE.WARRANTY,
        status: this.searchWarrantyStatus,
        fromDate: this.dateFrom
          ? moment(this.dateFrom, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        toDate: this.dateTo
          ? moment(this.dateTo, 'DD/MM/YYYY HH:mm').format('yyyy-MM-DD 23:59')
          : null,
        saleId: this.selectedEmployee,
        companyId: this.selectedCompany,
        orderId: this.searchOrderId ? this.searchOrderId.trim() : '',
        searchIMEI: this.searchIMEI ? this.searchIMEI.trim() : '',
        searchWarrantyReasonId: this.selectedWarrantyReasonId,
      };

      const paramQuery = {
        params: params,
      };

      ApiService.query('bills', paramQuery)
        .then((response) => {
          const { data } = response.data;
          this.bills = [];
          this.totalPages = data.totalPage;
          this.totalQuantity = 0;
          this.totalAmount = 0;
          this.totalPayment = 0;
          this.totalItems = data.totalRow;
          this.bills = data.dataset.reduce((result, item) => {
            item.show = item.listProduct.length <= 0 ? true : false;
            item.showDetail = false;
            item.selected = false;
            result.push(item);
            return result;
          }, []);
        })
        .catch((err) => {
          makeToastFaile(`Lỗi: ${err}`);
        });
    },
    updateWarrantyBill(item) {
      this.$router.push({
        name: 'add-warranty-bill',
        query: { billId: item.billNumber, action: 'update' },
      });
    },
    onSelectedEmployee(option) {
      this.selectedEmployee = option.item.id;
      this.searchEmployee = option.item.fullName;
    },
    onInputChangeEmployee(text) {
      if (!text) {
        text = '';
        this.selectedEmployee = null;
      }
      this.searchEmployee = text;
      const filteredData = this.optionsEmployee
        .filter((item) => {
          return (
            removeAccents(item.fullName)
              .toLowerCase(item.fullName)
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionsEmployee = [...filteredData];
    },
    onSelectStore(option) {
      let index = this.optionsStore.findIndex(
        (item) => item.name == option.name,
      );
      this.optionsStore[index].checked = true;
      this.filteredStore = cloneDeep(this.optionsStore);
    },
    onRemoveStore(option) {
      let index = this.optionsStore.findIndex(
        (item) => item.name == option.name,
      );
      this.optionsStore[index].checked = false;
      this.filteredStore = cloneDeep(this.optionsStore);
    },
    onChangeCompany() {
      this.valueStore = [];
      this.fetchStore();
    },
    fetchStore: async function () {
      this.optionsStore = [];
      ApiService.get(
        `/stores/getStores?companyId=${this.selectedCompany}`,
      ).then((response) => {
        const stores = response.data.data;
        stores.map((element) => {
          let option = {
            code: element.id,
            name: element.shortName,
            checked: false,
          };
          this.optionsStore.push(option);
        });
        this.filteredStore = cloneDeep(this.optionsStore);
      });
    },
    showModalExcel() {
      const storeIds = this.valueStore.map((store) => store.code);
      this.excelParams = {
        fromDate: this.dateFrom,
        toDate: this.dateTo,
        warrantyReasonId: this.selectedWarrantyReasonId,
        status: this.searchWarrantyStatus,
        storeIds: storeIds,
      };
      this.$bvModal.show('modal-warranty-bill-excel');
    },
    convertPrice: function (number = 0) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
  },
};
</script>
<style scoped>
.lost-product-icon {
  margin-left: 0.5rem;
  vertical-align: middle;
  color: rgb(244, 18, 18);
}

.staff-info {
  display: flex;
  font-size: 0.87rem;
}

.text-1rem {
  font-size: 1rem;
}

.warranty-reason {
  font-weight: 500;
  font-size: 0.95rem;
  color: #5e5e64;
}

.return-from-bill {
  margin-bottom: 0px;
  padding-top: 4px;
}

.header-table-color {
  color: rgb(24, 28, 50);
}

.orderCode:hover {
  text-decoration: underline;
}

.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(1.35em + 1.1rem + 2px);
  width: auto;
}
.customPagination /deep/ .page-item-first .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item-prev .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-next .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-last .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item .page-link {
  border-radius: 0.42rem;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
  height: 2.25rem;
  min-width: 2.25rem;
  padding: 0.5rem;
  color: #7e8299;
}

.customPagination /deep/ .page-item {
  color: #ecf1f6;
}

.customPagination /deep/ .page-item .router-link-exact-active {
  color: #fff;
}

.customPagination /deep/ .page-item .page-link:hover {
  background-color: #3699ff;
  color: #fff;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  opacity: 1;
}

.table th,
.table td {
  vertical-align: middle;
}
.tdCenter {
  vertical-align: left !important;
}
.tdTextAlign {
  vertical-align: middle;
}
.tdTextAlignPrice {
  text-align: right !important;
  vertical-align: middle;
}
.tdNote {
  width: 19%;
  word-break: break-all;
  vertical-align: middle;
}
.wrapword {
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap; /* Chrome & Safari */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* CSS3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  word-break: break-all;
  white-space: normal;
}
.tdStatus {
  width: 10%;
}
.text-imei-no {
  font-size: 12px;
  font-style: italic;
  color: dodgerblue;
}

.table-bordered-list th {
  border: 0.5px solid #d2d4d6 !important;
  background-color: #e0e0e0;
}

.table-bordered-list td {
  border: 0.5px solid #f3f3f3;
  /* border: 0.5px solid #fdfbfb; */
  /* border: 0.5px solid #d5d5d5; */
  border-bottom: 0.5px solid #adadad;
}

.text-weight {
  font-weight: 550;
}
.font-size-gift {
  font-size: 11px;
}
.font-size-attach {
  font-size: 11px;
}
.tdCode {
  width: 5%;
}
.tdStore {
  width: 6.8%;
}
.tdCustomer {
  width: 7%;
}
.tdProductName {
  width: 25%;
}
.tdWarrantyReason {
  width: 10%;
}
.tdProductPrice {
  width: 8%;
}
.tdQuantity {
  width: 4%;
}
.tdDiscountItem {
  width: 5%;
}
.tdTotalAmount {
  width: 8%;
}
.tdTotalPayment {
  width: 12%;
  font-weight: 700;
}
.tdNote {
  width: 19%;
}
.tdAction {
  width: 5%;
}

.search-col {
  display: flex;
  align-items: center;
}
</style>
<style lang="scss">
.warranty-bill-page {
  .cursor-pointer {
    cursor: pointer;
  }
  .select-style {
    border-radius: 0.28rem;
    box-shadow: none;
    border: 1px solid #ced4da;
  }
  input.form-control {
    border: 1px solid #ced4da;
  }
  .dashed {
    border: 1px dashed #464e5f;
    border-style: none none dashed;
    color: #fff;
    background-color: #fff;
  }
  .dropdown-toggle.nav-link:after,
  .dropdown-toggle.btn:after {
    content: none !important;
    margin-left: 0;
  }
  .dropdown-menu {
    border: 0 !important;
    border-style: none !important;
  }
}
</style>
